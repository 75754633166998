<template>
  <div
    class="background-image-container absolute background-image-transparent relative"
  >
    <div
      class="flex justify-end relative text-YellowKeno text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl"
    >
      <div
        v-if="forStyle.length < 21"
        class="flex text-white absolute 4xl:top-36 top-24 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10 text:"
      >
        <p class="font-normal mt-4 2xl:mt-6 4xl:mt-8">{{ forStyle.length }}</p>
        <p class="font-normal mt-8 2xl:mt-10 4xl:mt-14">/</p>
        <p class="mt-12 2xl:mt-14 3xl:mt-16 4xl:mt-20">20</p>
      </div>
      <div
        v-if="forStyle.length > 20"
        class="flex text-white absolute 4xl:top-36 top-24 2xl:top-28 right-0 transform -translate-x-1/2 -translate-y-2/3 z-10"
      >
        <p class="font-normal mt-4 2xl:mt-6 4xl:mt-8">20</p>
        <p class="font-normal text-3xl mt-8 2xl:mt-10 4xl:mt-14">/</p>
        <p class="mt-12 2xl:mt-14 3xl:mt-16 4xl:mt-20">20</p>
      </div>
    </div>

    <div
      ref="animatedElement"
      v-if="num !== 0"
      class="flex my-auto bottom-0 justify-center h-screen rounded-full text-center"
      :style="{
        '--rotation': randomRotation,
      }"
      :class="['number', entranceAnimation]"
    >
      <div
        class="flex shakingAnimation justify-center 2xl:w-110 2xl:h-110 w-100 h-100 3xl:py-2 4xl:w-140 4xl:h-140 m-auto p-auto text-black font-bold text-above9xl py-0"
      >
        <img
          class="object-contain w-100 h-100 2xl:w-105 2xl:h-105 3xl:w-120 3xl:h-120 4xl:w-140 4xl:h-140"
          :src="bg"
          :alt="num"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event/EventBus";
import { mapState } from "vuex";
export default {
  data() {
    return {
      randomRotation: "",
      rotations: ["-10deg", "-5deg", "0deg", "5deg", "10deg"],
      numSound: null,
    };
  },
  props: {
    ball: {
      type: String,
      //   required: true,
      default: "w-100 h-100",
    },
    indicator: {
      type: String,
      //   required: true,
      default: "text-3xl",
    },
  },
  created() {
    EventBus.$on("randomRotationValue", () => {
      this.randomRotationValue();
    });
    // EventBus.$on("playNumSound", () => {
    //   this.numSound = document.createElement("audio");
    //   this.playSound();
    // });
    // EventBus.$on("playClickSound", () => {
    //   this.playClickSound();
    // });
  },
  beforeDestroy() {
    // EventBus.$off("playNumSound");
    // EventBus.$off("playClickSound");
    EventBus.$off("randomRotationValue");
    // EventBus.$off("runDrawingFunctions");
  },
  computed: {
    bg() {
      return this.imageUrls[this.num - 1];
    },
    ...mapState("LoadImages", ["imageUrls"]),
    ...mapState("Game", ["forStyle"]),
    ...mapState("Game", ["num"]),
    ...mapState("Game", ["entranceAnimation"]),
    // ...mapState("Game", ["randomRotation"]),
  },
  methods: {
    randomRotationValue() {
      this.randomRotation =
        this.rotations[Math.floor(Math.random() * this.rotations.length)];
    },
    playClickSound() {
      const Audio1 = document.createElement("video");
      Audio1.src = "/sounds/additional/BallSound.opus";
      Audio1.autoplay = true;
    },
    playSound() {
      // <!-- console.log("hello in play sound:", this.num); -->
      this.numSound = document.createElement("audio");
      let toPlay = Number(this.num);
      this.numSound.src = "/sounds/" + toPlay + "a" + ".opus";
      this.numSound.autoplay = true;
    },
  },
};
</script>

<style>
.background-image-container {
  position: relative;
  height: 100vh;
}
.background-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../../../public/BG.png"); */
  /* background-image: url("../../../public/New/emptyNumerBG.png"); */
  background-image: url("../../../public/emptyNumersCropped.png");
  background-size: cover;
  background-position: center;
  /* background-repeat: no-repeat; */
}
.background-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../../../public/FG.png"); */
  /* background-image: url("../../../public/New/emptyNumerFG.png"); */
  background-image: url("../../../public/emptyNumers.png");
  background-size: cover;
  background-position: center;
  /* background-repeat: no-repeat; */
}
.entrance {
  animation-name: entrance;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  /* animation: ease-in; */
  /* animation-name: entrance; */
}

.exit {
  animation-name: exit;
}
@keyframes entrance {
  0% {
    opacity: 1;
    transform: scale(0.5) translateY(100%);
  }
  60% {
    transform: scale(1) translateY(0%);
    opacity: 0.9;
  }
  62% {
    transform: scale(1) translateY(97%);
    opacity: 0.9;
  }
  100% {
    transform: scale(1) translateY(0%) rotate(var(--rotation));
    opacity: 0.9;
  }
}
@keyframes exit {
  from {
    opacity: 0.9;
    transform: scale(1) translateY(0%);
  }
  to {
    opacity: 0;
    transform: scale(0.5) translateY(-1000px);
  }
}
.shakingAnimation {
  animation: shaking 0.15s infinite;
}
@keyframes shaking {
  0% {
    transform: rotate(0deg) translate(-0.5px, 0);
  }

  20% {
    transform: rotate(0.3deg) translate(0.5px, 0);
  }

  40% {
    transform: rotate(-0.3deg) translate(-0.5px, 0);
  }

  60% {
    transform: rotate(0.3deg) translate(0.5px, 0);
  }

  80% {
    transform: rotate(-0.3deg) translate(-0.5px, 0);
  }

  100% {
    transform: rotate(0deg);
  }
}
@keyframes bounce-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.3);
  }
}

.bounce {
  animation: bounce-animation 0.5s ease-in-out 1;
  z-index: 1000;
}
</style>
