var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"grid grid-cols-10 gap-0.5 3xl:gap-y-1 4xl:gap-y-0 4xl:gap-x-0.5 w-95 p-0.5"},_vm._l((80),function(i){return _c('div',{key:i,ref:"cards",refInFor:true,staticClass:"card font-family"},[_c('div',{class:{
          'lucky rounded-2xl text-center w-16 h-16 bounce  text-xl5 2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-24 3xl:h-26 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28':
            _vm.forStyle.includes(i) && i < 41,
          'lucky2 rounded-2xl text-center w-16 h-16 bounce  text-xl5  2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-24 3xl:h-26 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28 ':
            _vm.forStyle.includes(i) && i > 40,
          'text-xl5  one text-N3 w-16 h-16 rounded-2xl s text-center  2xl:text-xl6 2xl:w-20 2xl:h-20 3xl:text-xl7 3xl:w-24 3xl:h-26 object-fit  4xl:text-xl8 4xl:w-28 4xl:h-28':
            !_vm.forStyle.includes(i),
        },staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(i)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }