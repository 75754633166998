<template>
  <div id="numFont" class="h-screen svg w-screen">
    <div v-if="spinner"><EstablishingConnxn /></div>
    <!-- <div
      class="lg:hidden w-screen h-screen bg-gradient-to-l from-D1 via-D2 to-D3 items-center flex justify-center b"
    >
      <div>
        <div class="flex">
          <img
            class="w-32 h-32 object-contain"
            src="../../../public/logo.svg"
            alt=""
          />
          <div
            class="text-9xl bg-gradient-to-br from-redo via-7 to-YellowKeno text-transparent bg-clip-text uppercase"
          >
            Keno
          </div>
        </div>
        <div
          class="py-10 text-center bg-gradient-to-br from-YellowKeno via-7 to-YellowKeno text-transparent bg-clip-text"
        >
          <div class="text-4xl">mobile screen is under development!</div>
        </div>
      </div>
    </div> -->
    <div v-if="!spinner">
      <div v-if="status !== 'video' && status !== 'lastGames'">
        <div v-if="status !== 'Video'" class="grid grid-cols-5 h-screen">
          <div class="col-span-3">
            <Header />
            <div class="">
              <Numbers class="w-full pl-12" />
            </div>
            <Footer />
          </div>

          <div class="col-span-2 text-center w-full">
            <!-- <transition name="fade" mode="out-in" :key="status"> -->
            <timer
              v-if="status == 'Timing'"
              class="w-full h-screen bg-gradient-to-b from-D1 via-D2 to-D3"
            />
            <Drawing v-if="status == 'Drawing'" class="w-full" />
            <!-- </transition> -->
          </div>
        </div>
      </div>
      <!-- last game -->
      <div v-if="status == 'lastGames'" class="w-screen h-screen">
        <DrawnHistory
          textSize="text-4xl lg:text-3xl 3xl:text-5xl 4xl:text-6xl  "
          gameIdClass="text-5xl lg:text-5xl lg:w-28 w-32  2xl:w-36   3xl:w-40   4xl:w-44 2xl:text-6xl 3xl:text-6xl 4xl:text-7xl"
          numbersClass="lg:h-12 lg:w-12 xl:h-14 xl:w-14   rounded-full 2xl:w-16 2xl:h-16 3xl:w-18 3xl:h-18 4xl:w-24 4xl:h-24 4xl:py-3"
        />
      </div>
      <!-- video -->
      <div v-if="status == 'Video'">
        <video-player />
      </div>
      <!-- </transition> -->
    </div>
    <!-- <transition name="fade" mode="out-in" :key="status"> -->
  </div>
</template>

<script>
import VideoPlayer from "../../components/VideoPlayer.vue";
import io from "socket.io-client";
import { EventBus } from "@/event/EventBus";
import { mapState } from "vuex";
import Numbers from "@/components/Main/Numbers.vue";
import Header from "@/components/Main/Header.vue";
import Footer from "@/components/Main/Footer.vue";
import EstablishingConnxn from "@/components/Main/EstablishingConnxn.vue";
import Timer from "@/components/Main/Timer.vue";
import DrawnHistory from "../../components/DrawnHistory.vue";
import Drawing from "../../components/Main/Drawing.vue";
export default {
  components: {
    Numbers,
    Header,
    Footer,
    Timer,
    Drawing,
    VideoPlayer,
    DrawnHistory,
    EstablishingConnxn,
  },
  data() {
    return {
      spinner: true,
      socket: {},
      audioElement: null,
      shuffle: null,
      numSound: null,
    };
  },
  created() {
    this.socket = io("https://server.proximastech.com");
    EventBus.$on("runTimerFunctions", () => {
      this.numSound = document.createElement("audio");
      this.runTimerFunction();
    });
    EventBus.$on("runDrawingFunctions", () => {
      this.runDrawingFunctions();
    });
    EventBus.$on("stopService", (data) => {
      this.stopService(data ? data : null);
    });
    EventBus.$on("playTimerSong", () => {
      this.PlayTimerMusic();
    });
    EventBus.$on("playClickSound", () => {
      this.playClickSound();
    });
    EventBus.$on("playNumSound", () => {
      this.numSound = document.createElement("audio");
      this.playSound();
    });
  },
  beforeDestroy() {
    EventBus.$off("runTimerFunctions");
    EventBus.$off("runDrawingFunctions");
    EventBus.$off("stopService");
    EventBus.$off("playTimerSong");
    EventBus.$off("playClickSound");
    EventBus.$off("playNumSound");
  },
  mounted() {
    this.socket.on("message", (message) => {
      if (this.numSound) {
        this.numSound.remove();
        this.numSound = null;
      }
      if (this.shuffle) {
        this.shuffle.remove();
        this.shuffle = null;
      }
      this.$store.dispatch("Game/settingUp", message);
      this.enterFullscreen();
      this.spinner = false;
      // console.log("message in v7: ", message.);
    });
  },
  watch: {
    // num(newValue) {
    //   this.playSound(newValue);
    // },
  },
  computed: {
    ...mapState("Game", ["num"]),
    ...mapState("Game", ["status"]),
    // ...mapState("Game", ["audioElement"]),
    // ...mapState("Game", ["shuffle"]),
  },
  methods: {
    enterFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    runTimerFunction() {
      if (this.numSound) {
        this.numSound.remove();
        this.numSound = null;
      }

      this.PlayTimerMusic();
      this.stopShufflingSound();
      // console.log("running timer functions");
    },
    runDrawingFunctions() {
      if (this.audioElement) {
        this.audioElement.remove();
        this.audioElement = null;
      }
      this.playShufflingSound();
      this.stopTimerMusic();
      // console.log("running drawing functions");
    },
    // playSound() {
    //   console.log("hello in play sound:", this.num);
    //   this.numSound.pause();
    //   let toPlay = Number(this.num);
    //   this.numSound.src = "/sounds/" + toPlay + "a" + ".opus";
    //   this.numSound.load();
    //   this.numSound.play();
    // },
    playSound() {
      // console.log("hello in play sound:", this.num);
      this.numSound = document.createElement("audio");
      let toPlay = Number(this.num);
      this.numSound.src = "/sounds/" + toPlay + "a" + ".opus";
      this.numSound.autoplay = true;
    },
    PlayTimerMusic() {
      this.stopShufflingSound();
      if (!this.audioElement) {
        const audioSources = [
          "/sounds/additional/Track1.opus",
          "/sounds/additional/Track2.opus",
          "/sounds/additional/Track3.opus",
        ];
        const randomIndex = Math.floor(Math.random() * audioSources.length);
        const randomSource = audioSources[randomIndex];
        this.audioElement = document.createElement("audio");
        this.audioElement.src = randomSource;
        this.audioElement.autoplay = true;
        this.audioElement.loop = true;

        // Append audio element to the document body or desired container
        document.body.appendChild(this.audioElement);
      }
    },
    playShufflingSound() {
      this.shuffle = null;
      this.shuffle = document.createElement("video");
      this.shuffle.src = "/sounds/additional/MachineSound.opus";
      this.shuffle.volume = 0.15; // Set volume to 50%
      this.shuffle.autoplay = true;
      this.shuffle.loop = true;
    },
    stopShufflingSound() {
      if (this.shuffle) {
        this.shuffle.pause();
        this.shuffle.remove();
        // this.shuffle.currentTime = 0;
        // this.shuffle.src = "";
        this.shuffle = null;
      }
    },
    playClickSound() {
      const Audio1 = document.createElement("video");
      Audio1.src = "/sounds/additional/BallSound.opus";
      Audio1.autoplay = true;
    },
    stopTimerMusic() {
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement = null; // reset the reference to clear it from memory
      }
    },
    stopService(data) {
      this.stopTimerMusic();
      this.stopShufflingSound();
      if (data == "logout") {
        this.socket.disconnect();
        this.socket = null;
      }
    },
  },
};
</script>

<style>
.scale-transition-enter-active {
  transition: all 500ms ease-in-out;
}

.scale-transition-leave-active {
  transition: all 500ms ease-in-out;
}

.scale-150 {
  transform: scale(1.5);
}

.scale-120 {
  transform: scale(1.2);
}
#Myfont {
  overflow-y: hidden !important;
  overflow-x: hidden !important ;
}
/* .body {
  overflow-y: hidden !important;
  overflow-x: hidden !important ;
} */
.container {
  position: relative;
  padding: 0;
}
.item {
  width: 100%;
  height: 30px;
  background-color: #f3f3f3;
  border: 1px solid #666;
  box-sizing: border-box;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
/* TransitionGroup */
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 2s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(120px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
.show {
  transform: translateX(0);
  opacity: 1;
}

.stagger-item {
  /* Add your existing styles here */
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: translateX(-20px);
}
/* .stagger-item {
  transition: opacity 0.5s ease-in-out;
}

.stagger-enter,
.stagger-leave-to {
  opacity: 0;
}

.stagger-enter-active,
.stagger-leave-active {
  transition-delay: 0.2s;
} */

.number {
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* keep final keyframe styles after animation completes */
}
#numFont {
  font-family: "Rajdhani";
  overflow-y: hidden !important;
  overflow-x: hidden !important ;
}
@font-face {
  font-family: "Rajdhani";
  src: url("../../assets/Rajdhani/Rajdhani-Bold.ttf") format("woff2");
}
.svg {
  background-image: url("../../assets/SvgBg.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}
.number {
  animation-duration: 0.5s;
  animation-fill-mode: forwards; /* keep final keyframe styles after animation completes */
}

.entrance {
  animation-name: entrance;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  /* animation: ease-in; */
  /* animation-name: entrance; */
}

.exit {
  animation-name: exit;
}

@keyframes entrance {
  0% {
    opacity: 1;
    transform: scale(0.5) translateY(100%);
  }
  60% {
    transform: scale(1) translateY(0%);
    opacity: 0.9;
  }
  62% {
    transform: scale(1) translateY(97%);
    opacity: 0.9;
  }
  100% {
    transform: scale(1) translateY(0%) rotate(var(--rotation));
    opacity: 0.9;
  }
}
/* .shakingAnimation {
  animation: shaking 0.3s infinite;
}
@keyframes shaking {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-0.6px, -0.4px);
  }
  40% {
    transform: translate(0.6px, -0.4px);
  }
  60% {
    transform: translate(-0.6px, 0.4px);
  }
  80% {
    transform: translate(0.6px, -0.4px);
  }
  100% {
    transform: translate(0);
  }
} */
.shakingAnimation {
  animation: shaking 0.15s infinite;
}
.card {
  padding: 0;
}

.card > div {
  width: 100%;
  padding: 0;
  margin: 0;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
/* @keyframes shaking {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(0.4deg);
  }

  40% {
    transform: rotate(-0.4deg);
  }

  60% {
    transform: rotate(0.4deg);
  }

  80% {
    transform: rotate(-0.4deg);
  }

  100% {
    transform: rotate(0deg);
  }
} */
@keyframes shaking {
  0% {
    transform: rotate(0deg) translate(-0.3px, 0);
  }

  20% {
    transform: rotate(0.2deg) translate(0.3px, 0);
  }

  40% {
    transform: rotate(-0.2deg) translate(-0.3px, 0);
  }

  60% {
    transform: rotate(0.2deg) translate(0.3px, 0);
  }

  80% {
    transform: rotate(-0.2deg) translate(-0.3px, 0);
  }

  100% {
    transform: rotate(0deg);
  }
}
@keyframes exit {
  from {
    opacity: 0.9;
    transform: scale(1) translateY(0%);
  }
  to {
    opacity: 0;
    transform: scale(0.5) translateY(-1000px);
  }
}
</style>
