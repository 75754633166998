<template>
  <div>
    <div class="flex justify-between pl-12 pt-10">
      <div
        class="flex space-x-3 font-extrabold text-6xl 2xl:text-7xl 3xl:text-7xl 4xl:text-8xl"
      >
        <div class="pt-2 2xl:pt-3">
          <img
            class="h-12 2xl:h-14 3xl:h-16 4xl:h-22 object-contain"
            src="../../../public/New/Draw.svg"
            alt=""
          />
        </div>
        <div
          @click="logout()"
          class="text-white m-0 pt-1 hidden xl:block 2xl:hidden 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}xl
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 2xl:block 3xl:hidden pt-1 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}2xl
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 3xl:block 4xl:hidden pt-1 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}3xl
        </div>
        <div
          @click="logout()"
          class="text-white m-0 hidden 4xl:block 5xl:hidden pt-1 2xl:pt-2 4xl:pt-2"
        >
          {{ paddedValue }}4xl
        </div>
      </div>
      <div
        :style="{
          opacity: num >= 1 && num <= 40 ? '100%' : '0%',
        }"
        class="rounded-md text-center pr-8"
      >
        <div class="mt-2 3xl:mt-3">
          <img
            class="object-contain h-12 2xl:h-14 3xl:h-16 4xl:h-22"
            src="../../../public/New/heads.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event/EventBus";
import { mapState } from "vuex";
export default {
  computed: {
    paddedValue() {
      const subtractedGameId = this.gameId - 1;
      const value = subtractedGameId.toString().padStart(3, "0");
      return value;
    },
    ...mapState("Game", ["gameId"]),
    ...mapState("Game", ["num"]),
  },
  beforeDestroy() {
    EventBus.$off("stopService");
    // EventBus.$off("runDrawingFunctions");
  },
  methods: {
    async logout() {
      EventBus.$emit("stopService", "logout");
      const routeToHome = () => {
        localStorage.clear();
        this.$store.dispatch("Authentication/logOut");
        this.$router.push({
          path: "/",
        });
      };
      window.setTimeout(routeToHome, 1000);
    },
  },
};
</script>

<style></style>
