<template>
  <div class="text-center">
    <p
      v-if="timerDone || countdownTime == '59:59'"
      class="bg-gradient-to-b from-time via-time to-black text-transparent bg-clip-text text-center"
      :class="betClosedClass"
    >
      Bet Closed
    </p>
    <!-- mx-28 2xl:ml-32 3xl:ml-36 4xl:ml-40 -->
    <p
      v-if="countdownTime !== '59:59'"
      class="bg-gradient-to-b from-time via-time to-black text-transparent bg-clip-text text- xl:ml-32 2xl:ml-36 3xl:ml-36 4xl:ml-48"
      :class="timeClass"
    >
      {{ countdownTime }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    timeClass: {
      required: true,
      type: String,
    },
    betClosedClass: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState("BaseCountDown", ["formattedTime"]),
    ...mapState("BaseCountDown", ["timerDone"]),
    countdownTime() {
      return this.formattedTime;
    },
  },
};
</script>
