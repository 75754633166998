<template>
  <div>
    <div class="flex justify-between pl-12">
      <div class="flex space-x-4 text-7xl 3xl:text-8xl text-YellowKeno">
        <img
          class="h-14 2xl:h-16 3xl:h-18 4xl:h-20"
          src="../../../public/New/kenoLogo.svg"
          alt=""
          srcset=""
        />
        <!-- <p
          class="bg-gradient-to-r from-D2 via-D1 to-D3 text-transparent bg-clip-text"
        >
          KENO
        </p> -->
      </div>
      <div class="pr-8">
        <div
          class="text-center"
          :style="{
            opacity: num < 41 ? '0%' : '100%',
          }"
        >
          <div class="">
            <img
              class="object-contain h-12 2xl:h-14 3xl:h-16 4xl:h-22"
              src="../../../public/New/tails.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("Game", ["num"]),
  },
};
</script>

<style></style>
